<template>
  <div>
    <h3>Error: Server Side Error</h3>
    <p>
      サーバ側で問題が発生しました。<br>
      時間を空けてから再度アクセスをお願いします。
    </p>
    <p>
      <router-link :to="`/${lang}/`">トップに戻る</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Error500',
  computed: {
    lang() {
      return this.$route.params.lang
    },
  },
}
</script>

<style>

</style>